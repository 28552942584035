.result-title {
  margin-top: 1.5em;
  text-transform: uppercase;
}

.prompt-in-result {
  font-weight: normal;
}

.prompt-in-result-bold {
  font-weight: bold;
}

.result-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  margin-top: 1.2em;
  white-space: pre-wrap;
}

.send-result-button {
  text-align: center;
  margin-top: 1.5em;
}
