@font-face {
  font-family: "Eleuther";
  src: url("fonts/eleuther.woff2") format("woff2");
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,700;1,900&display=swap");

@import "styles/colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
td,
tr,
th,
table,
div,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $black;
}

.bold {
  font-weight: bold;
}

.centralize-text {
  text-align: center;
}

html,
body {
  height: 100%;
}

body > div {
  height: 100%;
}
