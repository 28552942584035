@import "../styles/colors.scss";

.prompt-input {
  text-align: center;
}

.prompt-input textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  border: 1px solid $grey2;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 0.4em;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    border: 1px solid $blue;
  }
}

.dark .prompt-input textarea {
  background-color: $dark-theme-bg2;
  color: $white;

  &:focus {
    border: 1px solid $dark-theme-blue;
  }
}
