@import "../styles/colors.scss";

.header-container {
  height: 3.2em;
  background-color: $black;
  padding-top: 0.625em;
}

.header-container .content-wrapper {
  flex-direction: row;
}

header h1.logo {
  font-size: 1.4em;
  font-family: "Eleuther", "Inter", sans-serif;
  font-weight: 400;
  height: 3em;
}

header h1.logo .logo-image {
  width: 40px;
  height: 40px;
}

.logo-text {
  text-align: left;
  position: relative;
  left: 0.45em;
  bottom: 0.4em;
  color: $white;
}

h1.logo-text a {
  text-decoration: none;
  color: $white;
}

.head-right {
  margin-left: auto;

  .dark-mode-switch {
    position: relative;
    top: 0.7em;

    img {
      cursor: pointer;
    }
  }
}

.dark .head-right .dark-mode-switch img {
  transform: rotate(180deg);
}
