$black: #000000;
$white: #ffffff;
$green: #699f56;
$blue: #34566f;
$grey: #959595;
$grey2: #d8d8d8;
$red: #aa4926;
$orange: #cc7832;

// dark theme

$dark-theme-bg: #1d1e20;
$dark-theme-bg2: #1d2327;
$dark-theme-text: #dbdbdb;
$dark-theme-blue: #00bbff;
