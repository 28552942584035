.model-controls {
  display: flex;
  justify-content: space-evenly;
  margin: 2em 0 1.6em 0;
}

.slider-title {
  text-align: center;
  font-size: 0.8em;
}

.slider-value {
  text-align: center;
  font-size: 0.8em;
}
