@import "../styles/colors.scss";

.prompt-list-container {
  background-color: $white;
  max-width: 60%;
  top: 20px;
  position: relative;
  border: 1px solid $grey2;
  padding: 0.3em 0.6em;
  margin: 0 auto;
  box-shadow: 0 3px 10px rgb(34 25 25 / 40%);
}

.dark .prompt-list-container {
  background-color: $dark-theme-bg;
}

ul.prompt-list {
  margin-top: 20px;
  list-style-type: none;
  overflow-y: auto;
  height: 400px;
  padding-right: 0.6em;
}

ul.prompt-list li {
  margin-bottom: 10px;
  margin-top: 5px;
  color: $blue;
  cursor: pointer;
  padding: 10px 0px;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px dashed $blue;
}

.dark ul.prompt-list li {
  color: $dark-theme-blue;
}

ul.prompt-list .item-link {
  font-size: 9px;
  color: #444444;
  margin-bottom: 5px;
}

ul.prompt-list li:hover {
  color: $grey;
}

.prompt-list-overlay {
  width: 100%;
  position: absolute;
  z-index: 7;
  top: 30px;
}

.prompt-head-title {
  text-align: center;
}

.close-icon {
  background-color: transparent;
  border: 0px none;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 3px;
  width: 25px;
}

.close-icon:hover {
  opacity: 0.8;
}
