@import "../styles/colors.scss";

footer {
  margin-top: 2em;
  padding: 2em 0;
}

.footer-content {
  font-size: 0.67em;
}

.footer-text-center {
  text-align: center;
}

.footer-content a {
  text-decoration: underline;
  color: $blue;
}

.dark .footer-content a {
  color: $dark-theme-blue;
}
